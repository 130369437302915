import Banner from "./Banner"
import Features from "./Features"
import Testimonials from "./Testimonials"

function Home(props) {
    return (
        <>
            <Banner />
            <Features />
            <Testimonials />
        </>
    )
}

export default Home