import { Container, Row, Image, Col } from 'react-bootstrap'
import BlockchainImg from "../../assets/19962853_6198944-ai.svg"

function Work(props) {
    return (
        <div className="services-portfolio mb-5">
            <section>
                <Container className="py-5">
                    <Row className="justify-content-center">
                        <Col md={8} className="mt-5 text-center">
                            {/* <p className="text-secondary mb-4">Our Proven Track Record of Successful Projects in Software Development and Beyond</p> */}
                            <h1 className="mb-4">Explore Our Work</h1>
                            <h6 className="text-secondary font-weight-light">Our delivered apps are always centered around innovative concepts that demand customized software solutions. Our projects encompass a wide range, from single applications designed for startups to extensive national and global solutions.</h6>
                        </Col>
                    </Row>

                    <Row className="mt-5 justify-content-center">
                        <Col md={3} className="text-center p-5">
                            <h3>600+</h3>
                            <p className='text-secondary'>projects delivered since 2000</p>
                        </Col>
                        <Col md={3} className="text-center p-5">
                            <h3>70%</h3>
                            <p className='text-secondary'>of clients are mid-sized and large businesses</p>
                        </Col>
                        <Col md={3} className="text-center p-5">
                            <h3>11</h3>
                            <p className='text-secondary'>years is the longest project duration</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='mb-8'>
                <Container className='project-section'>
                    <Row>
                        <Col lg={5}>
                            <Row>
                                <Col lg={12} className='p-3'>
                                    <h2 className='mb-4'>Blockchain Projects</h2>
                                    <p>We specialize in designing and developing advanced blockchain solutions specifically tailored to the unique requirements and objectives of our clients. Our expertise and collaborations with industry leaders in the blockchain space enable us to create and implement cutting-edge technology solutions for blockchain projects.</p>
                                </Col>
                                <Col md={6} lg={12} className='p-3'>
                                    <h3 className='mb-3'><a className='project-name' href='/'>Talent Protocol</a></h3>
                                    <p>Talent Protocol is a revolutionary decentralized platform that harnesses the power of blockchain technology to connect creators, businesses, and investors. By providing a secure and transparent ecosystem, Talent Protocol empowers individuals to showcase their skills, collaborate on innovative projects, and attract investment opportunities. </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={7}>
                            <Image src={BlockchainImg} width="100%" height="100%" />
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )    
}

export default Work