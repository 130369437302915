import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Col, Container, Row, Image } from "react-bootstrap"
import { Link } from "react-router-dom"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import FeaturesImg from '../../assets/features-2.svg'

function Features(props) {
    return (
        <section className="Features py-5">
            <Container>
                <Row>
                    <Col md={12}>
                        <h2 className="mb-3 text-uppercase">Services</h2>
                        <Link to="/services" className="text-dark">View All Services<FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '8px' }} size="lg" /></Link>
                    </Col>
                </Row>
            </Container>
            <div className="py-5 service-32">
                <Container>
                    <Row className="wrap-feature-32">
                        <Col md={6} className="align-self-center">
                            <div className="pb-3 border-bottom">
                                <h3 className="my-3">Web Development</h3>
                                <p className="mt-3">Turning your vision into a beautifully designed and functional website that meets the highest standards of quality and performance.</p>
                            </div>
                            <div className="pb-3 border-bottom">
                                <h3 className="my-3">Mobile Development</h3>
                                <p className="mt-3">Professional mobile development that puts your business in the hands of your customers and creates a seamless experience across all devices.</p>
                            </div>
                            <div className="pb-3 border-bottom">
                                <h3 className="my-3">Blockchain Development</h3>
                                <p className="mt-3">Harnessing the power of blockchain technology to create groundbreaking solutions that delivers fast, reliable, and secure results.</p>
                            </div>
                            <div className="pb-3 border-bottom">
                                <h3 className="my-3">UI/UX Design</h3>
                                <p className="mt-3">Providing delightful user experiences that leave a lasting impression and seamlessly integrates with your brand identity and business goals.</p>
                            </div>
                        </Col>
                        <Col md={{ span: 5, offset: 1 }}>
                            <Image src={FeaturesImg} fluid width="100%" rounded height="100%" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}

export default Features