import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Col, Container, Row, Badge, Button, Form } from "react-bootstrap"
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"
import emailjs from 'emailjs-com';

function Contact(props) {
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_cds_contact', 'template_l930wmk', e.target, 'Dfq1Zu59Pb_J1mSrS')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      }

    return (
        <div className="contact">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} className="align-self-center">
                        <Badge bg="secondary" className="p-2 font-weight-normal">Contact</Badge>
                        <h1 className="title mt-3 mb-2">Count on us to aid you in advancing and leveling up!</h1>
                    </Col>
                </Row>
            </Container>

            <div className="py-5 service-19">
                <Container>
                    <Row>
                        <Col lg={{ span: 3, offset: 2 }}>
                            <h6 className="subtitle font-weight-light mt-4">With just one click, we can help you take your brand or product from great to incredible. Whether you want to discuss your project or your favorite gum flavor, we're all ears - fill in the form to share more details!</h6>
                        </Col>
                        <Col lg={{ span: 4, offset: 1 }}>
                            <Form className="m-4" onSubmit={sendEmail}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Your Name</Form.Label>
                                    <Form.Control type="text" name="from_name" />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" name="email"/>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>In what ways can we be of service to you?</Form.Label>
                                    <Form.Control as="textarea" rows={5} name="message"/>
                                </Form.Group>
                                <Button variant="secondary" type="submit">
                                    Send
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="py-5 service-19">
                <Container>
                    <Row>
                        <Col lg={{ span: 3, offset: 2 }}>
                            <span>Mail us at</span>
                            <h3 className="my-3"><a href="mailto:contact@codistinct.com" target="_blank" rel="noreferrer" className="subtitle">contact@codistinct.com</a></h3>
                        </Col>
                        <Col lg={{ span: 4, offset: 1 }}>
                            <span>Follow us at</span>
                            <Row>
                                <Col md={6}>
                                    <h3 className="my-3">
                                        <a href="https://www.linkedin.com/company/codistinct/" target="_blank" rel="noreferrer" className="subtitle">LinkedIn <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" /></a>
                                    </h3>
                                </Col>
                                <Col md={6}><h3 className="my-3"><a href="https://github.com/codistinct" target="_blank" rel="noreferrer" className="subtitle">GitHub <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" /></a></h3></Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Contact