import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useLocation } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { useEffect, useState } from "react";

function Footer(props) {
    const location = useLocation();
    const [showCTA, setShowCTA] = useState(true);

    useEffect(() => {
        if (location.pathname === "/contact") {
            setShowCTA(false);
        } else {
            setShowCTA(true);
        }
    }, [location.pathname])

    return (
        <footer className="footer-20192 main-background">
            <div className="site-section">
                <Container>
                    {showCTA && (
                        <div className="cta d-block d-md-flex align-items-center px-5">
                            <div>
                                <h2 className="mb-0">Ready for a next project?</h2>
                                <h3 className="text-dark">Let's get started!</h3>
                            </div>
                            <div className="ml-auto">
                                <Link to="/contact">
                                    <Button className="btn-dark rounded-0 py-3 px-5">Contact Us</Button>
                                </Link>
                            </div>
                        </div>
                    )}

                    <Row>
                        <Col sm>
                            <Link to="#" className="footer-logo">CODISTINCT</Link>
                            <p className="copyright">
                                <small>&copy; {(new Date().getFullYear())}</small>
                            </p>
                        </Col>

                        <Col sm={3}>
                            <h3>Explore</h3>
                            <ul className="list-unstyled links">
                                <li><Link to="/services">Services</Link></li>
                                <li><Link to="/work">Work</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </Col>

                        <Col md={3}>
                            <h3>Follow us</h3>
                            <ul className="list-unstyled social">
                                <li><a href="https://www.linkedin.com/company/codistinct/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} size="lg" /></a></li>
                                <li><a href="https://github.com/codistinct" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGithub} size="lg" /></a></li>
                            </ul>
                        </Col>
                    </Row>
                    
                </Container>
            </div>
        </footer>
    )
}

export default Footer