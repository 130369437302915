// import { Container, Row, Col } from 'react-bootstrap'

function Banner(props) {
    return (
        <header className='Banner'>
            {/* <img src={BannerLogoDesktop} className='bg-image desktop' alt='banner' />
            <img src={BannerLogoMobile} className='bg-image mobile' alt='banner' /> */}
        </header>
    )
}

export default Banner