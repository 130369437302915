import { Link } from "react-router-dom";
import {Navbar, Container, Nav, Offcanvas} from "react-bootstrap"

function Header(props) {
    return (
        <Navbar expand="lg" className="main-background" style={{ borderBottom: '1px solid #fff' }}>
            <Container>
                <Navbar.Brand href="/" style={{ color: '#fff' }}>
                    <img
                        alt="CODISTINCT"
                        src="/logo.svg"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        />{' '}
                    CODISTINCT
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Offcanvas placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Codistinct</Offcanvas.Title>
                    </Offcanvas.Header>

                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3" style={{gap: '20px'}}>
                            <Nav.Item>
                                <Link to="/services" className="header-link nav-link nav-item-link">Services</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/work" className="header-link nav-link nav-item-link">Work</Link>
                            </Nav.Item>
                            <Nav.Item style={{ color: '#fff' }}>
                                <Link to="/contact" className="header-link nav-link nav-item-link">Contact</Link>
                            </Nav.Item>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default Header;