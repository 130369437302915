import React from 'react'
import { Col, Container, Row } from "react-bootstrap"
import OwlCarousel from "react-owl-carousel"

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import TalentProtocolIcon from "../../../src/assets/talent-protocol.jpg"

function Testimonials(props) {

    const responsiveCarousel2 = {
        0: {
            items: 1
      
          },
          1650: {
            items: 1
          }
    };

    return (
        <div className="testimonial10 py-5 bg-inverse">
            <Container>
                <Row className="justify-content-center">
                    <Col md={7} className="text-center">
                        <h3 className="mb-3 text-white">FEW WORDS FROM OUR VALUABLE CUSTOMERS</h3>
                    </Col>
                </Row>
                <OwlCarousel className="owl-theme text-center testi10" loop={true} margin={30} autoplay={true} responsiveClass={true} responsive={responsiveCarousel2}>
                    <div className="item mb-5">
                        <div className="quote-bg">
                            <h3 className="font-weight-light text-white">Codistinct showcased expertise in various technologies, including Ruby, Ruby on Rails, React, and Solidity. The company led the rewrite of the search experience using elastic search and also worked on blockchain-related tasks, such as creating a token bridge for the platform. They proposed solutions to issues and delivered results, demonstrating a positive attitude and strong work ethic. The company's ability to work effectively in remote teams with different time zones was also noted.</h3>
                        </div>
                        <div className="customer-thumb my-3"><img src={TalentProtocolIcon} alt="wrapkit" className="rounded-circle" /></div>
                        <h6 className="text-white mb-0 font-weight-medium">Francisco Leal</h6>
                        <span className="text-white">CPO, Talent Protocol</span>
                    </div>
                </OwlCarousel>
            </Container>
        </div>
    )
}

export default Testimonials