import { Container, Row, Col, Image } from "react-bootstrap";
import ServicesImg from "../../assets/services-img.svg"
import WebDevImg from "../../assets/web-development.svg"
import MobileDevImg from "../../assets/mobile-development.svg"
import BlockchainDevImg from "../../assets/blockchain-development.svg"
import DesktopDevImg from "../../assets/desktop-app-development.svg"
import UIXImg from "../../assets/ux-design.svg"
import SEOImg from "../../assets/SEO.svg"

function Services(props) {
    return (
        <>
            <div className="static-slider6 bg-danger-gradiant">
                <div className="left-right-bg">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} className="align-self-center">
                                <h1 className="title mt-3 mb-2 text-white text-center">Revolutionize Your Business with Our Future-Ready Software Services</h1>
                            </Col>
                            <Col md={8} className="text-center mt-5 middle-image">
                                <Image fluid rounded="true" className="video-img" alt="services" src={ServicesImg} />
                            </Col>
                            
                        </Row>
                    </Container>
                </div>
            </div>

            <Container className="mt-5">
                <Row className="justify-content-center">
                    <Col md={8} className="align-self-center mt-4 subtitle font-weight-light">
                        <p className='text-center'>In today's ever-evolving digital landscape, it's crucial for businesses to stay ahead of the curve with cutting-edge software solutions. At Codistinct, we specialize in providing future-ready software services that revolutionize the way your business operates. From web and mobile applications to blockchain technology, we are dedicated to crafting innovative solutions that meet your unique needs and drive business growth. Our team of experts stays up-to-date with the latest trends and technologies to ensure that our clients receive the most advanced and effective solutions. Whether you're looking to streamline operations, improve customer engagement, or increase revenue, we have the expertise and experience to help you achieve your goals. Let us help you revolutionize your business with our future-ready software services.</p>
                    </Col>
                </Row>
            </Container>

            <div className="services-portfolio">
                <Container className="py-5">
                    <Row className="justify-content-center">
                        <Col md={8} className="mt-4 text-center">
                            {/* <p className="text-secondary mb-4">Transforming Ideas into Reality with Our Expert Software Services</p> */}
                            <h1 className="mb-4">Explore Our Services</h1>
                            <h6 className="text-secondary font-weight-light">From ideation to deployment, we help turn your vision into a reality with our comprehensive software development services.</h6>
                        </Col>
                    </Row>
                </Container>

                <Container className="py-5 my-5 mt-0 pt-2">
                    <Row>
                        <Col md={6} className="p-5">
                            <div className="service-content">
                                <h3>Website Development</h3>
                                <p className="mt-4 text-secondary">At Codistinct, we specialize in creating stunning and user-friendly websites that drive results for businesses of all sizes. Our team of experienced web developers uses the latest technologies to build custom websites tailored to your unique business needs. Whether you need a simple website to showcase your services or a complex e-commerce platform to sell your products, we have the expertise and experience to bring your vision to life. We understand the importance of having a website that not only looks great but also performs seamlessly, which is why we focus on creating websites that are fast, secure, and optimized for search engines. Let us help you make a lasting impression online with our expert website development services.</p>
                            </div>
                        </Col>
                        <Col md={6} className="p-5">
                            <Image src={WebDevImg} fluid rounded className="service-image" />
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col md={6} className="p-5">
                            <Image src={MobileDevImg} fluid rounded className="service-image" />
                        </Col>
                        <Col md={6} className="p-5">
                            <div className="service-content">
                                <h3>Mobile Development</h3>
                                <p className="mt-4 text-secondary">At Codistinct, we understand that mobile devices have become an integral part of our daily lives, and having a mobile app for your business can make a significant impact on your bottom line. Our team of skilled mobile app developers is passionate about creating powerful and intuitive mobile apps that engage users and drive business growth. Whether you need a native iOS app for the Apple App Store or an Android app for Google Play, we have the expertise and experience to build custom mobile apps tailored to your specific business needs. We focus on creating mobile apps that are not only visually stunning but also perform seamlessly and deliver exceptional user experiences. Let us help you reach a wider audience and enhance your business's online presence with our expert mobile development services.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} className="p-5">
                            <div className="service-content">
                                <h3>Blockchain Development</h3>
                                <p className="mt-4 text-secondary">Blockchain technology is transforming the way businesses operate, and at Codistinct, we are at the forefront of this innovation. Our team of experienced blockchain developers is passionate about exploring the endless possibilities of blockchain technology to help businesses streamline their operations and increase efficiency. From building custom decentralized applications (dApps) to creating secure and scalable blockchain solutions, we have the expertise and experience to help you leverage the full potential of this revolutionary technology. We work closely with our clients to understand their unique business needs and develop tailored blockchain solutions that can help them achieve their goals. Let us help you stay ahead of the curve with our expert blockchain development services.</p>
                            </div>
                        </Col>
                        <Col md={6} className="p-5">
                            <Image src={BlockchainDevImg} fluid rounded className="service-image" />
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col md={6} className="p-5">
                            <Image src={DesktopDevImg} fluid rounded className="service-image" />
                        </Col>
                        <Col md={6} className="p-5">
                            <div className="service-content">
                                <h3>Desktop App Development</h3>
                                <p className="mt-4 text-secondary">At Codistinct, we understand the importance of having a powerful desktop application that can handle complex business operations efficiently. Our team of skilled desktop app developers is passionate about creating robust and intuitive desktop applications that deliver exceptional user experiences. Whether you need a Windows or Mac desktop application, we have the expertise and experience to build custom desktop apps tailored to your unique business needs. We focus on creating desktop applications that are not only visually stunning but also perform seamlessly and integrate seamlessly with other business applications. Let us help you transform your business with our expert desktop app development services.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} className="p-5">
                            <div className="service-content">
                                <h3>UI/UX Design</h3>
                                <p className="mt-4 text-secondary">At Codistinct, we understand the importance of having a visually stunning and user-friendly website or application. Our team of skilled UI/UX designers is passionate about creating intuitive and engaging interfaces that deliver exceptional user experiences. From wireframing to prototyping and user testing, we work closely with our clients to understand their unique business needs and design interfaces that align with their brand vision and business goals. We focus on creating interfaces that are not only visually appealing but also easy to use, ensuring that users can navigate your website or application seamlessly. Let us help you enhance your business's online presence and user engagement with our expert UI/UX design services.</p>
                            </div>
                        </Col>
                        <Col md={6} className="p-5">
                            <Image src={UIXImg} fluid rounded className="service-image" />
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col md={6} className="p-5">
                            <Image src={SEOImg} fluid rounded className="service-image" />
                        </Col>
                        <Col md={6} className="p-5">
                            <div className="service-content">
                                <h3>SEO & Digital Marketing</h3>
                                <p className="mt-4 text-secondary">At Codistinct, we understand the importance of having a strong online presence and the role that SEO & digital marketing play in achieving this. Our team of experienced SEO and digital marketing experts is passionate about helping businesses of all sizes achieve their online goals. We focus on developing data-driven strategies that are tailored to each client's unique business needs, helping them improve their website's visibility, drive targeted traffic, and increase conversions. From SEO audits to keyword research, content creation, social media marketing, and PPC advertising, we provide a range of SEO & digital marketing services to help businesses reach their full potential online. Let us help you enhance your online visibility and drive growth with our expert SEO & digital marketing services.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    );
}

export default Services