import './App.scss';
import Home from './components/Home';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Contact from './components/Contact';
import Work from './components/Work';
import Services from './components/Services';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="work" element={<Work />} />
          <Route path="services" element={<Services />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
